import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  numberList: numberList[];
  typeList: string[];
  spacing: number;

  ngOnInit(): void {
    this.typeList = ["+", "-"];
    this.typeList = ["+"];
  }
}

export class numberList {
  type: string;
  n1: number;
  n2: number;
}

